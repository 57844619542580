.hebrew_text {
    direction: rtl;
    text-align: right;
}

.on_image {
    top: 25%;
    right: 5%;
    display: flex;
    flex-direction: column;

    position: absolute;
    color: rgb(255, 255, 255);
}

@font-face {
    font-family: Rubik;
    src: url(../../fonts/Rubik-VariableFont_wght.ttf);
}

.p_title {
    font-size: 65px;
    font-style: normal;
    line-height: 100%;
    font-weight: 700;
    font-family: Rubik;
}

.p_txt {
    margin-top: -30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 145%;
    white-space: pre-wrap;
}

.basic_button {
    width: 240px;
    height: 62px;
    border: none;
    margin-top: 30px;
    flex-shrink: 0;
    box-shadow: 0px 3.6px 0px 0px #292929;
    background: var(--Linear, linear-gradient(90deg, #FEDB41 0%, #FC3 100%));
    border-radius: 31px;
    color: var(--292929, #292929);
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
    transition: 0.2s all ease;
}

.basic_button:hover {
    cursor: pointer;
    box-shadow: 0px 2px 0px 0px rgba(41, 41, 41, 0.00);
    color: white;
    background: #292929;
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;

    transition: 0.2s all ease;
    transform: scale(1);
}

@media only screen and (max-width: 600px) {

    .on_image {
        top: 40%;
    }

    .p_title {
        font-size: 35px;
    }

    .p_txt {
        font-size: 15px;
    }

    .basic_button {
        width: 166px;
        height: 52px;
        font-size: 15px;
        line-height: 122%;
    }

    .basic_button:hover {
        font-size: 18px;
    }
}