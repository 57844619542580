.section_contianer {
    position: relative;
    margin-top: 8%;
    align-items: center;
    text-align: center;
    display: block;
    direction: rtl;
}

.first_text {
    padding-top: 100px;
    color: var(--434446, #434446);
    text-align: center;
    font-family: Rubik;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
}

.second_text {
    color: var(--292929, #292929);
    text-align: center;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
}

.barcode {
    margin-top: 70px;
    margin-bottom: 265px;
    width: 148px;
    height: 148px;
    top: 4742px;
    left: 646px;
}