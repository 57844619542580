.box {
    border: 2px solid #D8E5FF;
    width: 285px;
    height: 106px;
    border-radius: 20px;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: Rubik;
    line-height: 55%;
    color: #292929;
    direction: rtl;
}
.text_container {    
    margin: 15px;
}
.second_text {
    font-weight: 400;
}


@media only screen and (max-width: 600px) {
    .box {
        padding: 3%;
        width: 145px;
        height: 145px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;     
        text-align: center;
        font-size: 13px;
        line-height: 15px;
        line-height: 100%;
        font-weight: 700;
    }
    .text_container {    
        margin: 0;
    }
    .first_text {
        width: 92px;
        margin: 12px auto 5px;
    }
    .second_text {
        margin: 0;
    }
    .normal_image {
        height: 40%;
    }
    .larger_image {
        height: 55%;
    }
}