.topics_line {
    margin-top: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    position: relative;
    justify-content: center;
}
@media only screen and (max-width:1700px){
.topics_line {
    margin-top: 0;
}
}