.text_container {
    padding-top: 4%;
}

.big_title {
    color: var(--434446, #434446);
    position: relative;
    text-align: center;
    font-family: Rubik;
    font-size: 2.344vw;
    font-style: normal;
    font-weight: 700;
    line-height: 50%;
}

.lil_title {
    color: var(--585-d-60, #585D60);
    position: relative;
    text-align: center;
    font-family: Rubik;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
}

.hebrew_text {
    direction: rtl;
}

.carousle_container {
    width: 80%;
    margin-top: 5%;
    margin-right: 10%;
    margin-left: 10%
}

.button_container {
    margin-top: 5%;
}

.basic_button {
    margin: auto;
    display: block;
    width: 240px;
    height: 62px;
    border: none;
    flex-shrink: 0;
    box-shadow: 0px 3.6px 0px 0px rgba(125, 170, 255, 1);
    background: linear-gradient(to left, rgba(34, 92, 202, 1), rgba(60, 127, 255, 1));
    border-radius: 31px;
    color: white;
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
    transition: 0.2s all ease;
    margin-top: 1.5%;
    position: relative;
}

.basic_button:hover {
    cursor: pointer;
    box-shadow: 0px 2px 0px 0px rgba(41, 41, 41, 0.00);
    color: white;
    background: #292929;
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;

    transition: 0.5s all ease;
    transform: scale(1);
}

@media only screen and (max-width:600px) {
    .carousle_container {
        width: 100%;
        margin: 70px auto;
    }

    .big_title {
        font-size: 35px;
        line-height: 110%;
    }

    .lil_title {
        font-size: 21px;
        line-height: 148%;
    }
    .basic_button {
        height: 52px;
        width: 166px;
        font-size: 15px;
    }
    .basic_button:hover {
        font-size: 15px;
    }
}