.bar_stuff {
    position: fixed;
    top: 0px;
    padding: 16px 0 17px 0;
    display: flex;
    gap: 20px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px rgba(216, 229, 255, 0.6) solid;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}

.left_side_bar {
    display: flex;
    left: 40px;
    gap: 20px;
    position: relative;
}

.logo {
    right: 30px;
    position: absolute;
    color: blue;
}

.nav_bar_button {
    width: 143px;
    height: 46px;
    border: none;
    flex-shrink: 0;
    box-shadow: 0px 3.6px 0px 0px #292929;
    background: var(--Linear, linear-gradient(90deg, #FEDB41 0%, #FC3 100%));
    border-radius: 23px;
    color: var(--292929, #292929);
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
    transition: 0.2s all ease;
}

.nav_bar_button:hover {
    cursor: pointer;
    box-shadow: 0px 2px 0px 0px rgba(41, 41, 41, 0.00);
    color: white;
    background: #292929;
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;

    transition: 0.2s all ease;
    transform: scale(1);
}

@font-face {
    font-family: Rubik;
    src: url(../../fonts/Rubik-VariableFont_wght.ttf);
}

@media only screen and (max-width:600px) {
    .nav_bar_button {
        width: 123px;
        height: 43px;
        font-size: 13px;
    }

    .nav_bar_button:hover {
        font-size: 13px;
    }
}