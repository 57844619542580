.button_container {
    width: 160px;
    height: 42px;
    border-radius: 6px;
    box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.30);
    display: flex;
    cursor: pointer;
}

.default_container {
    background-color: white;
    background: var(--WhiteGradien, linear-gradient(90deg, #FFF 0.18%, #F3F3F3 99.73%));
}

.inverted_container {
    background-color: white;
    background: var(--Style, linear-gradient(270deg, #225CCA 0%, #3C7FFF 100%))
}

.image {
    height: 23px;
    width: auto;
    margin: auto 5px auto 15px;
    
}

@font-face {
    font-family: Poppins;
    src: url(../../fonts/Poppins-Regular.ttf);
}

.text_container {
    margin-left: 10px;
    margin-top: 0px;
}

.small_txt {
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 40%;
}

.large_txt {
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 0%;
}

.default_text {
    color: #225CCA;
}

.inverted_text {
    color: #F6F9FF;
}

@media only screen and (max-width:600px) {
    .button_container {
        width: 200px;
        height: 56px;
        text-align: left;
    }
    .small_txt {
        font-size: 12px;
    }
    .large_txt {
        font-size: 18px;
    }
}