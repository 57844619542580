.large_img {
	position: relative;
	left: 5%;
	top: 5%
}

.bar_img {
	height: 475px;
	position: absolute;
	left: 82%;
	margin-top: 13%
}

.small_img1{
	position: absolute;
	width: 50px;
	margin-top: 13%;
	left: 85%
}

.small_img2{
	position: absolute;
	width: 50px;
	margin-top: 23.5%;
	left: 85%
}

.small_img3{
	position: absolute;
	width: 50px;
	margin-top: 34.5%;
	left: 85%
}

.background_img_container {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-color: #D8E5FF;
	
}

.background_img_container_fixed {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: #D8E5FF;
	left: 0;
	top: 0;
}

@font-face {
	font-family: Rubik;
	src: url(../../fonts/Rubik-VariableFont_wght.ttf);
}

.container_on_img {
	display: flex;
}

@keyframes smooth-appear-first-to-second-container {
	0% {
		margin-top: 193px;
	}
	100% {
		margin-top: 345px;
	}
}

@keyframes smooth-appear-second-to-third-container {
	0% {
		margin-top: 345px;
	}
	100% {
		margin-top: 478px;
	}
}

@keyframes smooth-appear-second-to-first-container {
	0% {
		margin-top: 345px;
	}
	100% {
		margin-top: 193px;
	}
}

@keyframes smooth-appear-third-to-second-container {
	0% {
		margin-top: 478px;
	}
	100% {
		margin-top: 345px;
	}
}

.first_text_container {
	width: 27.5%;
	height: 30.5%;
	margin-left: 50%;
	margin-top: 10%;
	direction: rtl;
	position: absolute;
	max-width: 30em;
	background-color: #fff;
	padding: 1.125em 1.5em;
	font-size: 1.25em;
	border-radius: 1rem;
	box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}


.second_text_container {
	width: 27.5%;
	height: 30.5%;
	margin-left: 50%;
	direction: rtl;
	position: absolute;
	max-width: 30em;
	background-color: #fff;
	padding: 1.125em 1.5em;
	font-size: 1.25em;
	border-radius: 1rem;
	box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.third_text_container {
	width: 27.5%;
	height: 30.5%;
	margin-left: 50%;
	direction: rtl;
	position: absolute;
	max-width: 30em;
	background-color: #fff;
	padding: 1.125em 1.5em;
	font-size: 1.25em;
	border-radius: 1rem;
	box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
	margin-top: 100px;
}

.second_text_container::before {
	top: 130px !important;
}

.third_text_container::before {
	top: 210px !important;
}

.first_text_container_down {}

.second_text_container_down {animation: smooth-appear-first-to-second-container 0.25s ease-in-out both;}

.third_text_container_down {animation: smooth-appear-second-to-third-container 0.25s ease-in-out both;}

.first_text_container_up {animation: smooth-appear-second-to-first-container 0.25s ease-in-out both;}

.second_text_container_up {animation: smooth-appear-third-to-second-container 0.25s ease-in-out forwards;}

.third_text_container_up {}

.background_img_container_end {
	position: absolute;
	width: 100vw;
	height: 100vh;
	margin-top: 600px;
}

.text_container::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	left: 100%;
	top: 3em;
	border: .75rem solid transparent;
	border-right: none;

	border-left-color: #fff;
	filter: drop-shadow(0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

/* https://codepen.io/rikschennink/pen/mjywQb */
.title {
	font-size: 30px;
	font-family: Rubik;
}

.paragraph {
	font-size: 16px;
	font-family: Rubik;
}

.basic_button {
	width: 240px;
	height: 62px;
	border: none;
	margin-top: 30px;
	flex-shrink: 0;
	box-shadow: 0px 3.6px 0px 0px #292929;
	background: var(--Linear, linear-gradient(90deg, #FEDB41 0%, #FC3 100%));
	border-radius: 31px;
	color: var(--292929, #292929);
	text-align: center;
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 148%;
	transition: 0.2s all ease;
}

.basic_button:hover {
	cursor: pointer;
	box-shadow: 0px 2px 0px 0px rgba(41, 41, 41, 0.00);
	color: white;
	background: #292929;
	text-align: center;
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 148%;

	transition: 0.2s all ease;
	transform: scale(1);
}