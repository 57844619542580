.container {
    width: 100%;
    display: flex;
    margin-top: 4.4%;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 768px) {
    .container {
        margin-top: 100px;
    }
    .innerContainer {
        width: 85%;
    }
}

@media only screen and (min-width: 769px) {
    .container {
        margin-top: 100px;
    }
    .innerContainer {
        width: 70%;
    }
}

.innerContainer {
    padding-bottom: 30px;
}

p {
    line-height: 22px;
}

