.hebrew_text {
  direction: rtl;
  text-align: right;
}

.container_title_features_slides {
  position: relative;
  display: block;
  bottom: 30px;
}

.title_features_slides {
  position: relative;
  text-align: center;
  font-size: 45px;
  font-family: Rubik;
  top: 80px;
  font-weight: 600;
}

.button_features_slides {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  margin: 80px;
}


.basic_button {
  width: 240px;
  height: 62px;
  border: none;
  flex-shrink: 0;
  box-shadow: 0px 3.6px 0px 0px #292929;
  background: var(--Linear, linear-gradient(90deg, #FEDB41 0%, #FC3 100%));
  border-radius: 31px;
  color: var(--292929, #292929);
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;
  transition: 0.2s all ease;
}

.basic_button:hover {
  cursor: pointer;
  box-shadow: 0px 2px 0px 0px rgba(41, 41, 41, 0.00);
  color: white;
  background: #292929;
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;

  transition: 0.2s all ease;
  transform: scale(1);
}

@media only screen and (max-width:600px) {
  .basic_button {
    width: 166px;
    height: 52px;
    font-size: 15px;
  }

  .basic_button:hover {
    font-size: 15px;
  }

}