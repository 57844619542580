.slide {
    position: relative;
    display: flex;
    gap: 122px;
    white-space: pre;
    width: 80%;
    top: 20px;
    align-items: center;
    justify-content: center;
    padding: 1% 5% 1% 5%;
    margin: 0% 5% 0% 5%;
}
.rtl_slide {
    direction: rtl;
}
.ltr_slide {
    direction: ltr;
}
.photo {
    width: 38%;
    height: 30%;
    border-radius: 100px;
    position: relative;
}
.title {
    color: var(--Style, #3C7FFF);
    text-align: right;
    font-family: Rubik;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
    direction: rtl;
}
.paragraph {
    color: var(--292929, #292929);
    text-align: right;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    direction: rtl;
    white-space: normal;
    width: 446px;
}

.text_container {
    position: relative;
    
}

@media only screen and (max-width:600px) {
    .slide {
        position: relative;
        display: block;
        gap: 122px;
        width: 80%;
        top: 20px;
        align-items: center;
        justify-content: center;
        padding: 1% 5% 1% 5%;
        margin: 0% 5% 0% 5%;
    }

    .title {
        font-size: 18px;
    }
    .paragraph {
        font-size: 15px;
        width: 100%;
        white-space: wrapped;
    }
    .photo {
        width: 310px;
        height: auto;
    }
    .text_container {
        direction: rtl;
    }
}