.circle_container {
    width: 49.816px;
    height: 49.816px;

    border: 1px solid #F7F9FF;
    border-radius: 50%;
    text-align: center;

    cursor: pointer;
}

.icon {
    margin-top: 30%;
    
}