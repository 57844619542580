.top_container {
    background-color: rgba(34, 92, 202, 1);
    width: 100%;
    height: 145px;
    border-bottom: 1px solid #7DAAFF;
    display: flex;
    justify-content: center;
}

.bottom_container {
    background-color: rgba(34, 92, 202, 1);
    width: 100%;
    height: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 7%;
}

@font-face {
    font-family: Heebo;
    src: url(../../fonts/Heebo-VariableFont_wght.ttf);
}

.mid_title {
    font-family: Heebo;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    direction: rtl;
    color: rgba(246, 249, 255, 1);
}

.top_left_container {
    align-self: center;
}

.top_mid_container {
    align-self: center;
    position: absolute;
}

.top_right_container {
    align-self: center;
    display: flex;
    margin-left: 70%;
    gap: 10px;
}

.copyrights {
    color: var(--Style, #7DAAFF);
    text-align: center;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    padding-top: 2px;
}

@media only screen and (max-width:600px){
    .top_container {
        background-color: rgba(34, 92, 202, 1);
        width: 100%;
        height: 200px;
        border-bottom: 1px solid #7DAAFF;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .top_left_container {
        margin-top: 0px;
    }

    .top_right_container {
        margin-left: 0;
        margin-top: 20px;
        gap: 30px;
    }
}