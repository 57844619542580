.section_contianer {
    position: relative;
    margin-top: 15%;
    align-items: center;
    text-align: center;
    display: block;
    direction: rtl;
}

.first_text {
    padding-top: 100px;
    color: var(--Style, #3C7FFF);
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
}

.second_text {
    color: var(--434446, #434446);
    text-align: center;
    font-family: Rubik;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
}

.third_text {
    color: var(--292929, #292929);
    text-align: center;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    margin: auto;
    width: 310px;
}

.commercial_buttons {
    margin: 70px auto 120px;
    display: inline-flex;
    flex-direction: column;
    direction: ltr;
    gap: 25px;
}
