@font-face {
    font-family: Roboto;
    src: url(../../fonts/Heebo-VariableFont_wght.ttf);
}

.container {
    height: 200px;
    width: 70%;
    margin: auto;
    border: 2px solid;
    border-radius: 30px;
    border-color: #D8E5FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    text-align: center;
}

.hebrew_text {
    text-align: center;
    direction: rtl;
}

.stars_line {
    height: 20%;
    margin:0;
    padding:0;
}

.recommendation_text {
    vertical-align: text-bottom;
    width: 100%;
    text-align: center;
    font-family: Roboto;
    font-size: 1.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    margin:0;
    padding:0;
}

.author {
    color: var(--Style, #3C7FFF);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    height: 20%;
    vertical-align: bottom;
    margin:0;
    padding:0;
}

@media only screen and (max-width:600px) {
    .container {
        width: 285px;
        height: 275px;
        margin: auto;
    }
    .recommendation_text {
        font-size: 18px;
    }
    .author {
        font-size: 16px;
    }
}